body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #ebf5ff;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  padding: 30px 50px 30px 50px;
}

#logo {
  margin-right: auto;
  cursor: pointer;
}

#container {
  height: 500px;
  background-color: #25292d;
}

.menuItem {
  color: white;
  font-size: 15px;
  font-family: SFProTextLight;
  margin-right: 60px;
  cursor: pointer;
}

#navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

#btnDownload {
  color: white;
  font-family: SFProTextLight;
  background: linear-gradient(to top, #8671ff 0%, #32a5ff 100%);
  padding: 15px 25px 15px 25px;
  border-radius: 15px;
  font-size: 15px;
  border: none;
  justify-self: flex-end !important;
  margin-left: auto;
  cursor: pointer;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 50px 30px 50px;
}

#title {
  margin-top: 50px;
  color: white;
  font-family: SFProTextLight;
  font-size: 47px;
  width: 650px;
  text-align: center;
}

#phone {
  margin-top: 30px;
}

#container3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin-top: 80px;
  padding: 0px 50px 30px 50px;
}

#container2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin-top: 20px;
  padding: 380px 50px 30px 50px;
}

#monetizaTrabajo {
  width: 38%;
  color: #32a5ff;
  font-size: 100px;
  font-family: SFProDisplayBold;
  padding-right: 70px;
}

#monetizaTrabajo span {
  color: #25292d;
}

#descriptionContainer {
  width: 50%;
}

#description {
  padding-top: 100px;
  font-family: SFProTextLight;
  font-size: 30px;
  line-height: 50px;
}

#descriptionLink {
  margin-top: 50px;
  color: #8671ff;
  font-size: 20px;
  font-family: SFProTextLight;
}

#container3Title {
  width: 55%;
  color: #25292d;
  font-size: 80px;
  font-family: SFProDisplayBold;
  padding-right: 70px;
  text-align: center;
}

#container3Title span {
  color: #32a5ff;
}

#container3Description {
  width: 70%;
  color: #25292d;
  padding-top: 50px;
  font-family: SFProTextLight;
  font-size: 30px;
  line-height: 50px;
  text-align: center;
}

#services {
  width: 90%;
  margin-top: 30px;
  padding: 30px 50px 30px 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#serviceItem {
  background-color: #ffffff;
  width: 120px;
  height: 120px;
  padding: 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(10, 0, 0, 0.125);
  -moz-box-shadow: 0px 0px 10px 0px rgba(10, 0, 0, 0.125);
  box-shadow: 0px 0px 10px 0px rgba(10, 0, 0, 0.125);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

#serviceItem img {
  padding-top: 10px;
}

#serviceItem p {
  font-family: SFProTextLight;
  font-size: 16px;
  padding-top: 10px;
}

footer {
  background-color: #25292d;
  padding: 30px 50px 30px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer img {
  margin-top: 50px;
}

footer p {
  font-family: SFProTextLight;
  font-size: 40px;
  margin-top: 80px;
  width: 60%;
  text-align: center;
  color: #ffffff;
}

footer div {
  display: flex;
  flex-direction: row;
  width: 45%;
  align-self: center;
  justify-content: space-evenly;
  align-items: center;
}

footer div a {
  color: #899db4;
  font-family: SFProTextLight;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  text-align: center;
  align-self: center;
  justify-content: center;
}

@font-face {
  font-family: "SFProTextLight";
  src: local("SF-Pro-Text-Light"),
    url(./assets/fonts/SF-Pro-Text-Light.otf) format("opentype");
}

@font-face {
  font-family: "SFProDisplayBold";
  src: local("SF-Pro-Display-Bold"),
    url(./assets/fonts/SF-Pro-Display-Bold.otf) format("opentype");
}
